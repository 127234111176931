$(function () {
    $('.main-slider').slick({
        infinite: false,
        fade: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        slide: '.slide',
        dots: false
    });

    $('.other-products-slider').slick({
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        slide: '.product-box',
        dots: true
    });

    $('.blog-slider').slick({
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        slide: '.blog-post',
        dots: true,
        arrows: false
    });

    $('.menu-button').click(function () {
        $('body').toggleClass('menu-active')
    })

    $('section.projects .title-bar a').click(function () {
        $('section.projects .title-bar a').removeClass('active');
        $(this).addClass('active');

        var activeCategory = $(this).attr('id');
        $('section.projects .col-sm-3').hide();
        $('section.projects .col-sm-3.'+activeCategory).show();
    });
})